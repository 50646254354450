import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { slugs } from '../Constant/slugs';
import '../styles.css'
import { LogoURL } from '../Constant/api';
const SideBarBlock = ({
  allSlug, newSlug, location,
  Title, icon, setExpand, Expand,
  setMenuExpand
}) => {
  return (
    <div>
      <div className={location.pathname == allSlug ? "row active-sidebar" : "row passive-sidebar"}

      >
        <Link className={"Link col-10"} to={allSlug}>
          <div className={location.pathname == allSlug ? "active-sidebar-font" : "passive-sidebar-font"}
            onClick={() => {
              setMenuExpand(false)
            }}
          >
            &nbsp; &nbsp;{Title}
          </div>
        </Link>
        {newSlug !== "" &&
          <div className="col-1" style={{ cursor: 'pointer' }}
            onClick={() => {
              setExpand(!Expand)
            }}>
            <i className={Expand ? "fa fa-minus" : "fa fa-plus"} style={{ fontSize: "10px" }} />
          </div>
        }
      </div>
      {Expand && newSlug !== "" &&
        <>
          <Link className={location.pathname == allSlug ? "Link active-sidebar" : "Link passive-sidebar"}
            style={{ paddingLeft: "40px" }}
            to={allSlug}
          >
            <div className={location.pathname == allSlug ? "active-sidebar-font" : "passive-sidebar-font"}>
              All {Title}
            </div>
          </Link>
          <Link className={location.pathname == `/${newSlug}` ? "Link active-sidebar" : "Link passive-sidebar"}
            style={{ paddingLeft: "40px" }}
            to={newSlug}
          >
            <div className={location.pathname == `/newSlug` ? "active-sidebar-font" : "passive-sidebar-font"}>
              Add New {Title.slice(0, -1)}
            </div>
          </Link>
        </>
      }
    </div>
  )
}
export default function SideBar({ setMenuExpand }) {
  const LoginUser = useSelector((state) => state.LoginState);
  const [Menu, setMenu] = React.useState(false)
  const location = useLocation()

  const [ExamExpand, setExamExpand] = React.useState(false)
  const [CBTExamExpand, setCBTExamExpand] = React.useState(false)
  const [TextExamExpand, setTextExamExpand] = React.useState(false)
  const [DemoExamExpand, setDemoExamExpand] = React.useState(false)
  const [DailyExamExpand, setDailyExamExpand] = React.useState(false)
  const [ChapterWiseExamExpand, setChapterWiseExamExpand] = React.useState(false)
  const [BookExpand, setBookExpand] = React.useState(false)
  const [StudentExpand, setStudentExpand] = React.useState(false)
  const [BatchExpand, setBatchExpand] = React.useState(false)
  const [DailyResultsExpand, setDailyResultsExpand] = React.useState(false)
  const [ExamResultsExpand, setExamResultsExpand] = React.useState(false)

  return (
    <div>
      <div className="small-display-none">
        <div style={{ justifyContent: 'center', display: 'flex', padding: "30px", borderBottom: "1px solid #eee" }}>
          <img
            src={LogoURL}
            style={{ width: "170px", objectFit: 'contain' }}
          />
        </div>
      </div>

      <div className="small-display-block">
        <div className={"row passive-sidebar"} onClick={() => { setMenuExpand(false) }}>
          <div className={"passive-sidebar-font"}>
            <i className="fa fa-close" style={{ fontSize: "16px" }} />&nbsp; &nbsp;Close
          </div>
        </div>
      </div>
      {/* Home */}
      {/* {LoginUser.userRole} */}
      {LoginUser.userRole == "author" &&
        <>
          <SideBarBlock
            allSlug={slugs.all_exam} newSlug={slugs.new_exam}
            location={location} Title={"Set Soal UBT"}
            icon={""}
            Expand={ExamExpand}
            setExpand={setExamExpand}
          />
          {/* <SideBarBlock
            allSlug={slugs.all_cbt_exam} newSlug={slugs.new_cbt_exam}
            location={location} Title={"CBT Exams"}
            icon={""}
            Expand={DemoExamExpand}
            setExpand={setDemoExamExpand}
          /> */}
          {/* <SideBarBlock
            allSlug={slugs.all_text_book_exam} newSlug={slugs.new_text_exam}
            location={location} Title={"Text Book Exams"}
            icon={""}
            Expand={DailyExamExpand}
            setExpand={setDailyExamExpand}
          /> */}

          {/* <SideBarBlock
            allSlug={slugs.all_books} newSlug={slugs.new_book}
            location={location} Title={"All Books"}
            icon={""}
            setExpand={setBookExpand} Expand={BookExpand}
            setMenuExpand={setMenuExpand}
          /> */}

          {/* <SideBarBlock
            allSlug={slugs.all_videos} newSlug={slugs.new_video}
            location={location} Title={"All Videos"}
            icon={""}
            setExpand={setBatchExpand} Expand={BatchExpand}
            setMenuExpand={setMenuExpand}
          /> */}

          <SideBarBlock
            allSlug={slugs.all_results} newSlug={""}
            location={location} Title={"Hasil Ujian"}
            icon={""}
            setExpand={setBookExpand} Expand={BookExpand}
            setMenuExpand={setMenuExpand}
          />
          <SideBarBlock
            allSlug={slugs.all_students} newSlug={slugs.new_student}
            location={location} Title={"SISWA"}
            icon={""}
            setExpand={setStudentExpand} Expand={StudentExpand}
            setMenuExpand={setMenuExpand}
          />
          <SideBarBlock
            allSlug={slugs.all_batch} newSlug={slugs.new_batch}
            location={location} Title={"Kelas"}
            icon={""}
            setExpand={setStudentExpand} Expand={StudentExpand}
            setMenuExpand={setMenuExpand}
          />
          {/* <SideBarBlock
            allSlug={slugs.all_ljd_exam} newSlug={slugs.new_ljd_exam}
            location={location} Title={"LJD Exam"}
            icon={""}
            setExpand={setStudentExpand} Expand={StudentExpand}
            setMenuExpand={setMenuExpand}
          /> */}
          {/* <SideBarBlock
            allSlug={slugs.all_ljd_videos} newSlug={slugs.new_student}
            location={location} Title={"LJD Videos"}
            icon={""}
            setExpand={setStudentExpand} Expand={StudentExpand}
            setMenuExpand={setMenuExpand}
          />
          <SideBarBlock
            allSlug={slugs.ljd_exam_result_list} newSlug={""}
            location={location} Title={"LJD Results"}
            icon={""}
            setExpand={setStudentExpand} Expand={StudentExpand}
            setMenuExpand={setMenuExpand}
          /> */}
          {/* <div className="row" className="active-sidebar">
            Student Panel
          </div> */}
        </>
      }
      {/* <SideBarBlock
        allSlug={"/"} newSlug={""}
        location={location} Title={"Dashboard "}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      /> */}
      <SideBarBlock
        allSlug={slugs.exam} newSlug={""}
        location={location} Title={"All Exam "}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.exam_result_list} newSlug={""}
        location={location} Title={"All Exam Results"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      {/* <SideBarBlock
        allSlug={"/"} newSlug={""}
        location={location} Title={"Dashboard "}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.exam} newSlug={""}
        location={location} Title={"All Exam "}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.textbookexam} newSlug={""}
        location={location} Title={"Latihan Migguan"}
        icon={""}
        setExpand={setTextExamExpand} Expand={TextExamExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.cbtexam} newSlug={""}
        location={location} Title={"UBT Special"}
        icon={""}
        setExpand={setCBTExamExpand} Expand={CBTExamExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.exam_result_list} newSlug={""}
        location={location} Title={"All Exam Results"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
        <SideBarBlock
        allSlug={slugs.book_list_user} newSlug={""}
        location={location} Title={"Text Books"}
        icon={""}
        setExpand={setBatchExpand} Expand={BatchExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={"/profile"} newSlug={""}
        location={location} Title={"Profile"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      /> */}
    </div>
  )
}